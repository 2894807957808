.background-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.gradient-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;
  height: 100vh;
  width: 100%;
  background-color: #0a0a0a;
  background-image: radial-gradient(
    ellipse 80% 80% at 50% -20%,
    rgba(120, 119, 198, 0.3),
    rgba(255, 255, 255, 0)
  );
}

.background-content {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
}