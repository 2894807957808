* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

.stats-and-socials {
  background-color: #1e1e2b;
  color: white;
  width: 100%;
  padding: 1rem;
  border-radius: 12px;
  margin-top: 25px;
  border: 0.1px solid #525275;
  box-shadow: 0 0 12px #525275;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.stat-item {
  display: flex;
  align-items: center;
}

.stat-value {
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  margin-right: 0.5rem;
}

.stat-label {
  font-size: 16px;
  line-height: 1.2;
  color: #BBC2DE;
  font-weight: 300;
}

.socials {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.social-label {
  font-size: 16px;
  color: #BBC2DE;
  font-weight: 300;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.icon {
  width: 24px;
  height: 24px;
}

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .stats-and-socials {
    padding: 0.75rem;
  }

  .stats {
    flex-direction: row;
    gap: 2rem;
  }

  .stat-value {
    font-size: 36px;
  }

  .stat-label {
    font-size: 14px;
  }

  .socials {
    align-self: center;
  }

  .social-label {
    font-size: 14px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}

/* Tablet and larger */
@media (min-width: 768px) {
  .stats-and-socials {
    padding: 1.5rem;
  }

  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .stats {
    flex-direction: row;
    gap: 2rem;
  }

  .stat-value {
    font-size: 56px;
  }

  .stat-label {
    font-size: 18px;
  }

  .socials {
    align-items: flex-end;
  }
}

/* Desktop */
@media (min-width: 1024px) {
  .stats-and-socials {
    padding: 2rem 4.5rem;
  }

  .stat-value {
    font-size: 64px;
  }

  .stat-label {
    font-size: 20px;
  }

  .social-label {
    font-size: 20px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}