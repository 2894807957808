/* Technologies and Tools Styling */
.technologies-tools-section {
    margin-top: 2.5rem;
  }
  
  .section-title {
    font-size: 44px;
    margin-bottom: 1rem;
    color: #FFFFFF;
  }
  
  .section-description {
    font-size: 16px;
    font-weight: 300;
    color: #BBC2DE;
    margin-bottom: 2rem;
  }
  
  .technologies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1.5rem;
  }
  
  .technology-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e1e2b;
    border: 1px solid #525275;
    border-radius: 8px;
    padding: 1rem;
    aspect-ratio: 1 / 1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .technology-icon:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(82, 82, 117, 0.3);
  }
  
  .tech-svg-icon {
    width: 100%;
    height: 100%;
    fill: #BBC2DE;
  }
  
  @media (max-width: 768px) {
    .technologies-grid {
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      gap: 1rem;
    }
  
    .technology-icon {
      padding: 0.75rem;
    }
  }