:root {
  --data-animation-delay: 1.5s; /* Adjustable delay */
}

@keyframes gradient {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

@keyframes chartAnimation {
  0% { transform: translateY(20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.hero-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  padding: 60px 0;
  align-items: center;
}

.hero-content {
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-content h1 {
  font-size: 42px;
  color: #ffffff;
  margin-bottom: 24px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.code-word {
  color: #4ade80;
  white-space: nowrap;
  display: inline-block;
}

.creativity-word {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.data-word {
  display: inline-block;
}

.chart-letter {
  display: inline-block;
  animation: chartAnimation 0.5s ease-out forwards;
  opacity: 0;
  animation-delay: calc(var(--data-animation-delay) + (var(--char-index, 0) * 0.2s));
}

/* Rest of your existing CSS remains unchanged */

.hero-content p {
  font-size: 22px;
  color: #BBC2DE;
  margin-bottom: 32px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.cta-button {
  background-color: #4ade80;
  color: #0A0D2D;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  align-self: flex-start;
  height: 54px;
  width: 180px;
}

.hero-image {
  grid-column: 8 / -1;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
}

.hero-image svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 36px;
  }

  .hero-content p {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  .hero-section {
    grid-template-columns: 1fr;
  }

  .hero-content, .hero-image {
    grid-column: 1 / -1;
  }

  .hero-image {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 0 0;
  }

  .hero-content h1 {
    font-size: 28px;
  }

  .hero-content p {
    font-size: 14px;
  }

  .cta-button {
    width: 100%;
  }
}