.about-section {
  color: white;
  padding: 8rem 0;
  width: 100%;
  overflow: hidden; /* Prevent any potential overflow */
}

.content-wrapper {
  max-width: 100%;
  margin: 0 auto;
}

.about-subtitle {
  color: #BBC2DE;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 0.5rem;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

.about-title {
  font-size: 44px;
  margin-bottom: 1.5rem;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

.about-description {
  font-size: 16px;
  font-weight: 300;
  color: #BBC2DE;
  max-width: 800px;
  line-height: 1.6;
  margin-bottom: 2rem;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.service-box {
  aspect-ratio: 1 / 1;
  max-width: 250px;
  width: 100%;
  height: auto;
  background-color: #1e1e2b;
  border-radius: 12px;
  border: 0.1px solid #525275;
  box-shadow: 0 0 12px #525275;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.service-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  fill: #BBC2DE; /* Adjust color as needed */
}

.service-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

.service-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.service-box:hover::before {
  opacity: 1;
}

.service-box:hover {
  border: 0.1px solid #525275;
  box-shadow: 0 0 12px #525275;
}

@media (max-width: 768px) {
  .about-section {
    padding: 5rem 0;
  }

  .about-title {
    font-size: 2rem;
  }

  .services-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }

  .service-box {
    max-width: 200px;
  }
}