.footer {
    padding: 20px 0;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer-logo {
    width: 120px; /* Adjust this size as needed */
    height: auto;
    margin-bottom: 10px;
  }
  
  .footer-text {
    font-size: 14px;
    color: #BBC2DE; /* Adjust this color to match your design */
    text-align: center;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
  }