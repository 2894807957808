* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.app {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 128px;
}

@media (max-width: 1200px) {
  .app {
    padding: 0 64px;
  }
}

@media (max-width: 768px) {
  .app {
    padding: 0 32px;
  }
}