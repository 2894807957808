.navbar {
  padding: 48px 0;
  background-color: transparent;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.navbar-logo img {
  width: 210px;
  max-width: 100%;
}

.navbar-links {
  display: flex;
  gap: 40px;
  align-items: center;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.navbar-cta {
  background-color: #4ade80;
  color: #0A0D2D;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  width: 25px;
  height: 3px;
  background-color: white;
  margin-bottom: 5px;
  border-radius: 2px;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    align-items: center;
  }

  .navbar-logo {
    margin-bottom: 20px;
  }

  .navbar-logo img {
    width: 180px;
    max-width: 100%;
  }

  .navbar-links {
    flex-direction: column;
    width: 100%;
    gap: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .navbar-links.active {
    max-height: 300px;
  }

  .hamburger {
    display: flex;
    position: absolute;
    top: 56px;
    right: 20px;
  }

  .navbar-cta {
    margin-top: 20px;
  }
}